/* _transitions.scss */

// speeds map getter
@function speed($key: "default") {
  @return map-get($speeds, $key);
}

// transition getter
@function transition($keys, $speed: "default", $ease: ease-in-out) {
  $transitions: null;
  @each $key in $keys {
    $transitions: $transitions, $key speed($speed) $ease;
  }
  @return $transitions;
}

// pulse
@keyframes pulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

// expand
@keyframes expand {
  to {
    transform: scale(1.5);
  }
}

// shower
@keyframes burst {
  0% {
    opacity: 0;
    transform: translate(0, 0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

// scaleDown
@keyframes scaleDownward {
  from {
    opacity: 1;
  }
  to {
    transform: translateY(random(100) + px) scale(0.2);
    opacity: 0;
  }
}
