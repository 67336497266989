/* _backgrounds.scss */

.background {
  @each $key, $position in $background-positions {
    &-#{$key} {
      background-position: $position;
    }
  }

  &-cover {
    background-size: cover;
  }
}

.bg {
  @each $key, $color in $palette {
    &-#{$key} {
      background-color: $color;
    }
  }
}
