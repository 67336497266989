/* index.scss */
@import "../../styles/variables.scss";

/**
* theme variables
*/

/**
* bootstrap specific
*/
// ---colors
// $blue:       #007bff !default;
$blue: #1f2163 !default;
// $indigo:     #6610f2 !default;
// $purple:     #7c75c6 !default;
// $pink:       #e83e8c !default;
// $red:        #dc3545 !default;
// $orange:     #fd7e14 !default;
$orange: #f25d00 !default;
// $yellow:     #ffc107 !default;
// $green:      #28a745 !default;
$teal: #36bca5 !default;
$cyan: #66cdf3 !default;

// ---bootstrap

// $primary:    $blue !default;
$primary: $orange !default;
// $secondary:  $gray-600 !default;
$secondary: black !default;
// $success:    $green !default;
$info: $teal !default;
// $warning:    $yellow !default;
// $danger:     $red !default;
// $light:      $gray-100 !default;
// $dark:       $gray-800 !default;
$navbar-color: #1f1bb3;

// import theme modifiers
@import "modifiers";

// stays at botttom
@import "../../styles/base.scss";

.bg-navbar {
  background-color: $navbar-color !important;
}
