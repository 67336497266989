/* _shapes.scss */

.shape {
  &.extra-rounded {
    border-radius: 1em;
  }

  &.boxed {
    border-radius: 0;
  }
}

.after-border-radius-circle {
  &:after {
    border-radius: 50%;
  }
}
