/* _cursors.scss */

.cursor-pointer {
  cursor: pointer !important;
}

.btn:disabled,
.btn.disabled {
  cursor: inherit;
}
