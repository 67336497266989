.language-title {
  position: relative;
  background-color: white;
  border: 1px solid gray;
  color: gray;
  padding: 3px 5px;
  font-size: 0.7rem;
  border-radius: 20px 20px;
  margin-left: 10px;

  .tooltiptext {
    visibility: hidden;
    text-align: center;
    padding: 10px 20px;
    min-width: 60px;
    bottom: 100%;
    position: absolute;
    z-index: 1;
    margin-left: 20px;
    font-size: 0.8rem;
    box-shadow: 0px 1px 8px -5px #3f3f3f;
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}
