body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .body-content {
    margin-left: 5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .content {
    height: calc(100% - 7em);
  }

  .sidebar {
    width: 5rem !important;
  }

  .tab-content {
    margin-top: 2rem;
  }

  .mpng-ux-breadcrumbs {
    .breadcrumb {
      padding-left: 0rem !important;

      a,
      a:hover {
        color: rgb(23, 23, 23);
        text-decoration: none;
      }
    }
  }

  .col {
    .name-text {
      margin-left: 1rem;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// drill down to make full screen
html,
body,
#root,
div[class^="Section"] {
  height: 100%;
  width: 100%;
}

body,
#root,
div[class^="Section"] {
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

// set content to overflow
.content {
  overflow: auto;
}

// needed to scroll inside the ALA container
#root {
  overflow-y: scroll;
}
