/* variables.scss */
@use "sass:map";

// sizes
$size-map: (
  "xs": 5rem,
  "sm": 12rem,
  "md": 15rem,
  "lg": 20rem,
  "xl": 25rem
);

$text-sizes: (
  "xxs": 0.5rem,
  "xs": 0.8rem,
  "sm": 0.9rem,
  "md": 1rem,
  "lg": 1.2rem,
  "xl": 1.5rem,
  "xxl": 3rem
);

// speeds
$speeds: (
  "slowest": 0.75s,
  "slow": 0.5s,
  "default": 0.3s,
  "fast": 0.25s,
  "fastest": 0.2s
);

// backgrounds
$background-positions: (
  left-top: left top,
  left-center: left center,
  left-bottom: left bottom,
  right-top: right top,
  right-center: right center,
  right-bottom: right bottom,
  top: center top,
  center-top: center top,
  center: center,
  center-center: center center,
  center-bottom: center bottom,
  bottom: center bottom
);

// theme colors and shades
$themed-colors: (
  primary: var(--primary),
  secondary: var(--secondary),
  success: var(--success),
  info: var(--info),
  warning: var(--warning),
  danger: var(--danger)
);

$static-colors: (
  blue: var(--blue),
  indigo: var(--indigo),
  purple: var(--purple),
  pink: var(--pink),
  red: var(--red),
  orange: var(--orange),
  yellow: var(--yellow),
  green: var(--green),
  teal: var(--teal),
  cyan: var(--cyan)
);

$shades: (
  "white": var(--white),
  "light": var(--light),
  "gray-light": var(--gray-light),
  "gray-extra-light": var(--gray-extra-light),
  "gray": var(--gray),
  "gray-dark": var(--gray-dark),
  "dark": var(--dark),
  "black": var(--black),
  // Todo: In next major release, remove extra colors
  "teal-light": var(--teal-light),
  "purple-light": var(--purple-light),
  "teal-extra-light": var(--teal-extra-light),
  "blue-extra-light": var(--blue-extra-light)
);

$color-palette: (map-merge($themed-colors, $static-colors));
$extension-palette: (map-merge($static-colors, $shades));
$palette: (map-merge($color-palette, $shades));

:root {
  --gray-light: #dee2e6;
  --black: #000000;
  --gray-extra-light: #f1efed;

  // In the sake of preservation and easier maintenance, it is best
  // advised to not create arbitrary colors. Instead reserve this for
  // extending shades.
  //
  // Todo: Remove extra arbitrary colors
  --teal-light: #47a5c9;
  --purple-light: #8781bd;
  --teal-extra-light: #f3f9fb;
  --blue-extra-light: #f6f5fa;
}

$gray: var(--gray);
$gray-light: #dee2e6 !default;
$gray-extra-light: #f1efed !default;
$gray-dark: var(--gray-dark);

// remove extra colors
$teal-light: #47a5c9 !default;
$purple-light: #8781bd !default;
$teal-extra-light: #f3f9fb !default;
$blue-extra-light: #f6f5fa !default;
