.lesson-resources-modal.modal {
  height: auto !important;
  max-height: 95%;
  overflow-y: hidden !important;

  .modal-content {
    padding: 1rem;

    .modal-title {
      color: var(--gray);
    }

    .modal-header {
      .close {
        font-size: 3rem;
        font-weight: 500;
        margin-top: -1.5rem !important;
      }
    }
    .modal-body {
      max-height: calc(85vh - 3.5rem) !important;

      .title {
        margin-top: -0.5rem;
      }
    }
  }
}
