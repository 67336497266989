/* index.scss */
@import "../../styles/variables.scss";

/**
* theme variables
*/

/**
* bootstrap specific
*/
// ---colors
// $blue:       #007bff !default;
$blue: #1f2163 !default;
// $indigo:     #6610f2 !default;
$purple: #7c75c6 !default;
// $pink:       #e83e8c !default;
// $red:        #dc3545 !default;
// $orange:     #fd7e14 !default;
// $yellow:     #ffc107 !default;
// $green:      #28a745 !default;
$green: #6dd401 !default;
// $teal:       #20c997 !default;
// $cyan:       #17a2b8 !default
$cyan: #329485 !default;

// ---bootstrap

$primary: #1f1bb3 !default;
// $secondary:  $gray-600 !default;
$secondary: #6a0396 !default;
// $success:    $green !default;
$info: #1f2262 !default;
// $warning:    $yellow !default;
// $danger:     $red !default;
// $light:      $gray-100 !default;
// $dark:       $gray-800 !default;
$navbar-color: #6a0396;

// import theme modifiers
@import "modifiers";

// stays at botttom
@import "../../styles/base.scss";

.bg-navbar {
  background-color: $navbar-color !important;
}
