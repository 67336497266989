@media (min-width: 576px) {
  .modal-dialog-scrollable {
    max-height: 100% !important;
    height: 100% !important;
  }
}

.modal-content {
  max-height: 100% !important;
  height: 100% !important;
  overflow: auto !important;
}
