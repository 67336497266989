/* _borders.scss */

.border {
  &-gray {
    border-color: $gray;
  }
  &-gray-light {
    border-color: $gray-light;
  }
}
