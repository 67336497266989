/* _svgs.scss */

.stroke {
  stroke: var(--primary);

  @each $key, $color in $palette {
    &.stroke-#{$key} {
      stroke: $color;
    }
  }
}

.fill {
  fill: var(--primary);

  @each $key, $color in $palette {
    &.fill-#{$key} {
      fill: $color;
    }
  }
}
