/* _sizes.scss */

.size {
  $count: 1;

  @each $key, $size in $size-map {
    &.#{$key} {
      width: $size;

      &.rounded-circle,
      &.squared {
        height: $size;
      }

      &.landscape {
        height: calc(#{$size} * 0.75);
      }

      &.portrait {
        width: calc(#{$size} * 0.75);
        height: $size;
      }

      .truncate-lines {
        -webkit-line-clamp: $count;
      }
    }

    $count: $count + 1;
  }
}

/* aspect ratio */
.aspect-1-1 {
  padding-top: 100%;

  & > *:first-child,
  &:after {
    @extend .position-contain;
  }
}
